@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');
.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  color: white;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.page-wrapper {
  display: flex;
  min-height: 100vh;
  padding: 50px 0px;
  align-items: center;
}
img, .img-box img {
  max-width:100%;
}
.loader-img {
  width: 50px;
  border: none !important;
}
.autocompleteStyle {
  top: 20px;
  left: 20px;
  width: 30%;
  height: 50px;
  outline: none;
  padding: 0 22px;
  font-size: 14px;
  position: absolute;
  border-radius: 40px;
  text-overflow: ellipses;
  border: 1px solid transparent;
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.3);
}
.deleteIconStyle {
  top: 0px;
  left: 53%;
  width: 27px;
  height: 27px;
  z-index: 99999;
  margin-top: 5px;
  border-radius: 2px;
  cursor: pointer;
  position: absolute;
  background-size: 20px 20px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('assets/images/remove.png');
}
.btn-theme {
  cursor: pointer;
  text-align:center;
  border-radius: 50px !important;
  color: #FFF !important;
  padding: 10px 30px !important;
  border: solid 1px #0072cb !important;
  background-color: #0072cb !important;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.errorclass {
  color: red;
  margin-top: 30px;
}

.successclass {
  color: #0f5132;
  background: #d1e7dd;
  border-color: #badbcc;
}
#map {
  height: 100%;
}

/* * Optional: Makes the sample page fill the window. */

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Poppins', sans-serif;
}
#floating-panel {
  top: 10px;
  left: 25%;
  z-index: 5;
  padding: 5px;
  line-height: 30px;
  padding-left: 10px;
  position: absolute;
  text-align: center;
  background-color: #fff;
  border: 1px solid #999;
  font-family: "Roboto", "sans-serif";
}
.setbackcss {
/*  width:300px;*/
  /* margin-top: 70px; */
}
.directioncss {
  margin-top: -67px;
  margin-left: 135px;
}
.outercss {
  margin-left: 250px
}
#map_area, #buildingOrientation {
  min-height: 100vh;
  position: relative;
}
#map_area .next {
  right: 60px;
  bottom: 20px;
  position: fixed;
}
.map-alert {
  bottom: 0;
  width: 100%;
  padding: 10px;
  position: fixed;
  max-width: 500px;
}
.map-alert .alert {
  margin-bottom: 0;
}

.house-type > div {
  display: flex;
}
.house-type .form-control {
  border-radius: 50px;
}
.house-type .btn-theme {
  box-shadow: none;
  border-radius: 50px;
}
.house-typedetail {
  width: 100%;
}
.img-box img {
  border-radius: 30px;
  border: #ccc solid 1px;
}
.notes {
  display: flex;
  flex-flow: wrap;
}
.notes .item {
  position: relative;
  margin-right: 10px;
  padding: 10px 10px 10px 20px;
}
.notes .item:before {
  content: '';
  width: 10px;
  height: 10px;
  left: 5px;
  top: 50%;
  background-color: #ccc;
  transform: translateY(-50%);
  position: absolute;
}
.notes .buildable:before {
  background-color: #000;
}
.notes .outer:before {
  background-color: #F00
}
.notes .direction:before {
  background-color: #00F;
}